// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}

label:not(.form-check-label):not(.custom-file-label){
  font-weight: normal;
}

.modal-dialog{
  max-width:800px;
}

.v-text-field .v-label--active {
  transform: translateX(-11%) translateY(-18px) scale(.75)!important;
}

.nav-sidebar > .nav-item{
  overflow-x: hidden;
}

.hover-btn {
  top: 5px;
  right: 5px;
  display: none;
}

.nav-item:hover .hover-btn{
  display:block;
}

.nav.nav-treeview {
  position: relative;
  left: 15px;
}

.sidebar-collapse .nav.nav-treeview {
  left: 0;
}


.vdp-datepicker__clear-button {
  position: absolute;
  right: 0;
}

.modal .modal-header{
  background-color: #c1c1c1;
}

.cursor-pointer{
  cursor: pointer;
}

#pdfPreview{
  height: 600px;
}

.max-height-500{
  max-height: 500px;
}

.vdp-datepicker{
  width:100%;
}

.chargeBarcodeBox{
  height: 220px;
  border: 1px solid #ccc;
  overflow: auto;
}

.form-group{
  margin-bottom: 0;
}

.brand-link .brand-image{
  border-radius: 0;
}

.text-right input{
  text-align: right;
}


// .content-header{
//   padding: 10px 0.5rem !important;
// }